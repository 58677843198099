.App {
  text-align: center;
  background-color: #FAF9FA;
  padding-bottom: 100px;
}

.App-container {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: 16px;
  margin-right: 16px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-grid {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
}

.App-col {
  /* flex: 1; */
}

@media (max-width: 700px) {
  .App-grid {
    display: block;
  }

  .App-header {
    margin-left: initial;
    margin-right: initial;
  }
}
.Project {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  flex: 1;
}

.Project-box {
  background-color: white;
  border-radius: 10px;
  min-width: 342px;
  min-height: 208px;
  margin-top: 16px;
  margin-bottom: 16px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  text-decoration: none;
  color: white;

  /* animation */
  position: relative;
  top: 0;
  transition: top ease-out 0.15s, filter ease .15s;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

@media (hover: hover) and (pointer: fine) {
  .Project-box:hover {
    top: -5px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  }
}

.Project-box-image-top {
  background-position: top;
  background-size: cover;
}

.Project-box-image-center {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.Project-header {
  font-family: 'Avenir Next', 'Avenir', 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: left;
  /* identical to box height */

  display: flex;
  align-items: center;

  margin-top: 24px;

  color: #290029;

}

.Tags {
  margin-bottom: 8px;
  margin-right: 4px;
}

.Tag {
  font-family: 'Avenir Next', 'Avenir', 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 58px;
  height: 21px;
  margin-right: 4px;
  padding: 2px 4px 2px 4px;
  background: linear-gradient(90deg, #C04848 0%, #480048 100%);
  border-radius: 4px;
  /* color: white;
  text-decoration: none; */
}

@media (max-width: 700px) {
  .Project {
    margin-left: 16px;
    margin-right: 16px;
  }

  .Project-box {
    width: 100%;
    min-width: initial;
    min-height: initial;
  }
  .Project-box:before{
    content: "";
    display: block;
    padding-top: 60.82%;
  }
}
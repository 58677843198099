.ProjectPage-text {
  font-family: 'Avenir Next', 'Avenir', 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  align-self: stretch;
  color: #290029;
}

.ProjectPage {
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
}

@media (max-width: 700px) {
  .ProjectPage-text {
    margin-left: 16px;
    margin-right: 16px;
  }
}

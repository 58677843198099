.Bio {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  margin-top: 16px;
  overflow-x: auto;
  max-width: 100%;
  padding: 4px;
}

.Bio-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
}

.Bio-col {
  display: flex;
  flex-direction: column;
}

.Bio-sectionHead {
  font-family: 'Avenir Next', 'Avenir', 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: left;
  color: #290029;
}

.Bio-sectionHead a:link {
  text-decoration: inherit;
  color: inherit;
}

.Bio-sectionHead a:visited {
  text-decoration: inherit;
  color: inherit;
}

.Bio-box {
  background-color: white;
  border-radius: 10px;
  min-width: 342px;
  min-height: 208px;
  margin-top: 16px;
  margin-bottom: 16px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  /* animation */
  position: relative;
  top: 0;
  transition: top ease-out 0.15s, filter ease .15s;
}

@media (hover: hover) and (pointer: fine) {
  .Bio-box:hover {
    top: -5px;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
  }
}

.Bio-box-image-top {
  background-position: top;
  background-size: cover;
}

.Bio-box-image-center {
  background-size: cover;
  background-position: 50% 75%;
}

.Bio-text {
  font-family: 'Avenir Next', 'Avenir', 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: left;
  max-height: 208px;
  max-width: 410px;
  margin-left: 16px;
  align-self: center;
  color: #290029;
}

@media (max-width: 700px) {
  .Bio-container {
    margin-left: 16px;
    margin-right: 16px;
    display: block;
  }

  .Bio-col {
    /* flex: 1;
    align-self: stretch; */
  }

  .Bio-box {
    width: 100%;
    min-width: initial;
    min-height: initial;
  }
  .Bio-box:before{
    content: "";
    display: block;
    padding-top: 60.82%;
  }

  .Bio-text {
    margin-left: 16px;
    margin-right: 16px;
    max-width: initial;
    max-height: initial;
  }
}
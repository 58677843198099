.AppList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-self: flex-start;
  margin-top: 16px;
  overflow-x: auto;
  max-width: 100%;
  padding: 4px;
}

.AppList-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 24px;
  overflow-x: visible;
}

.AppList-col {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.AppList-sectionHead {
  font-family: 'Avenir Next', 'Avenir', 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: left;
  color: #290029;
}

.AppList-sectionSubHead {
  font-family: 'Avenir Next', 'Avenir', 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 44px;
  text-align: left;
  margin-left: 8px;
  color: #290029;
}

.AppItem {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  -webkit-transform: translateZ(0);
}

.AppItem a {
  height: 100px;
  width: 100px;
  background-image: url(https://picsum.photos/256/);
  background-size: cover;
  text-decoration: none;
  display: inline-block;
  margin-right: 24px;
  border-radius: 24px;
  position: relative;
}

.AppItem a:before,
.AppItem a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: 100%;
  /* -webkit-transform: scaleX(2) scaleY(1.05); */
  /* transform: scaleX(2) scaleY(1.05); */
  clip: rect(0, 66px, 100px, 34px);
  z-index: -1;
}

.AppItem a:after {
  /* -webkit-transform: scaleY(2) scaleX(1.05) rotate(90deg);
  transform: scaleY(2) scaleX(1.05) rotate(90deg); */
}

@media (max-width: 700px) {
  .AppList-container {
    margin-left: 16px;
  }

  .AppItem a {
    margin-right: 8px;
  }
}

@media (max-width: 400px) {
  .AppList-sectionSubHead {
    margin-left: 0px;
    display: block;
  }
}